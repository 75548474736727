<template>
  <nav class="w-full">
    <div
      class="w-full lg:w-4/5 xl:w-95p mx-auto flex justify-between items-center"
    >
      <!-- Logo -->
      <img
        src="@/assets/images/Logo-Quambio.svg"
        alt="Logo"
        class="h-24 w-24"
      />

      <!-- Menu -->
      <ul class="flex space-x-6 desktop-nav">
        <!-- <li class="relative">
          <a href="/admin/dashboard" class="text-secondary"> Admin </a>
        </li>
        <li class="relative">
          <a href="/app" class="text-secondary"> App </a>
        </li> -->
        <li class="relative">
          <a :href="'#about'" class="text-secondary">About</a>
        </li>
        <li class="relative">
          <a :href="'#news'" class="text-secondary">News</a>
        </li>
        <li class="relative">
          <a :href="'#contact-us'" class="text-secondary">Contact Us</a>
        </li>
        <li class="relative">
          <a :href="'#testimonial'" class="text-secondary">Testimonial</a>
        </li>
        <li class="relative">
          <a :href="'#join'" class="text-secondary">Join</a>
        </li>
      </ul>
      <!-- <ul class="flex space-x-6 desktop-nav">
          <li class="relative">
            <a :class="[isActive(''), 'text-secondary']" href="/">
              <span class="marker" :class="{ 'active': isActive('') }"></span>
              Home
            </a>
          </li>
          <li class="relative">
            <a :class="[isActive('about'), 'text-secondary']" href="#about">
              <span class="marker" :class="{ 'active': isActive('about') }"></span>
              About
            </a>
          </li>
          <li class="relative">
            <a :class="[isActive('news'), 'text-secondary']" href="#news">
              <span class="marker" :class="{ 'active': isActive('news') }"></span>
              News
            </a>
          </li>
          <li class="relative">
            <a :class="[isActive('contact-us'), 'text-secondary']" href="#contact-us">
              <span class="marker" :class="{ 'active': isActive('contact-us') }"></span>
              Contact Us
            </a>
          </li>
          <li class="relative">
            <a :class="[isActive('testimonial'), 'text-secondary']" href="#testimonial">
              <span class="marker" :class="{ 'active': isActive('testimonial') }"></span>
              Testimonial
            </a>
          </li>
          <li class="relative">
            <a :class="[isActive('join'), 'text-secondary']" href="#join">
              <span class="marker" :class="{ 'active': isActive('join') }"></span>
              Join
            </a>
          </li>
        </ul> -->

      <!-- Bouton de Login -->
      <div
        class="relative bg-custom-gradient p-px rounded-lg custom-shadow z-20"
      >
        <div class="relative custom-button-login rounded-[calc(0.5rem-1px)]">
          <CustomButton
            customClass="text-secondary"
            paddingX="px-6"
            href="/app"
          >
            Login
          </CustomButton>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import CustomButton from "@/components/elements/CustomButton.vue";

export default {
  name: "NavigationMenu",
  components: {
    CustomButton,
  },
  computed: {
    isActive() {
      return (page) => {
        return this.$route.path.includes(page) ? "active" : "";
      };
    },
  },
};
</script>

<style scoped>
a:hover {
  color: #00ffcc;
}

a {
  position: relative;
}

.marker {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: transparent;
  /* Par défaut, invisible */
}

.marker.active {
  background-color: #00ffcc;
}

nav {
  top: 0;
  z-index: 50;
  height: 0;
}

.custom-button-login {
  background-image: linear-gradient(to bottom right, #05485c, #045f6b);
  /* Couleur de fond du bouton */
  color: #ffffff;
  /* Couleur du texte du bouton */
  padding: 0.2rem 0.5rem;
}

.custom-button-login:hover {
  background-color: #004494;
  /* Couleur du fond au survol */
}

@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }

  .desktop-nav {
    display: none;
  }
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  .desktop-nav {
    display: none;
  }
}
</style>
