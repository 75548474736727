<template>
  <div class="flex flex-col w-full mx-auto lg:w-4/5 xl:w-95p" id="testimonial">
    <div class="flex flex-col items-center justify-center w-full mx-auto mt-12 mb-12">
      <!-- Première partie : Titre et texte alignés verticalement -->
      <h1 class="mb-4 text-4xl font-bold text-primary">{{ title }}</h1>
      <p class="text-lg text-center text-dark">{{ text }}</p>
    </div>
  </div>
  <div
    class="flex flex-col w-full pt-8 pb-8 mb-12 overflow-x-scroll no-scrollbar"
    ref="scrollContainer"
  >
    <div class="flex card-wrapper">
      <div class="flex-shrink-0 w-0.5/4"></div>
      <div v-for="(card, index) in cards" :key="index" class="flex-shrink-0 w-1/4 mx-2">
        <CustomReviewCard
          :photo="card.photo"
          :iconStart="card.iconStart"
          :person="card.person"
          :description="card.description"
          :location="card.location"
          :iconEnd="card.iconEnd"
          :iconStars="card.iconStars"
        />
      </div>
    </div>
  </div>
  <!-- Conteneur des boutons de défilement centré -->
  <div class="flex justify-center pb-4 mb-24 custom-hidden">
    <!-- Bouton Gauche -->
    <button
      class="flex items-center justify-center p-3 mx-2 rounded-full left-gradient-button"
      @click="scrollLeft"
    >
      <img src="@/assets/images/icons/arrow-left.svg" alt="Left arrow" class="w-6 h-6" />
    </button>
    <!-- Bouton Droit -->
    <button
      class="flex items-center justify-center p-3 mx-2 rounded-full right-gradient-button"
      @click="scrollRight"
    >
      <img src="@/assets/images/icons/arrow-right.svg" alt="Right arrow" class="w-6 h-6" />
    </button>
  </div>
</template>

<script>
import CustomReviewCard from "./elements/CustomReviewCard.vue";
import photoTom from "@/assets/images/Tom.webp";
import iconStart from "@/assets/images/icons/iconStart.svg";
import iconEnd from "@/assets/images/icons/iconEnd.svg";
import iconStars5 from "@/assets/images/Stars-5.svg";

export default {
  name: "WhatPeopleSay",
  props: {
    title: {
      type: String,
      default: "What People Say About Us",
    },
    text: {
      type: String,
      default:
        "Our community already comprises thousands of members. Here is what a few of them are saying about our application.",
    },
  },
  components: {
    CustomReviewCard,
  },
  data() {
    return {
      cards: [
        {
          photo: photoTom,
          person: "Tom",
          iconStart: iconStart,
          iconStars: iconStars5,
          description:
            "I use the App every time I cycle. Seeing my carbon savings in real time is really motivating and pushes me to do more.",
          location: "Hamilton, Bermuda",
          iconEnd: iconEnd,
        },
        {
          photo: photoTom,
          person: "Elijah",
          iconStart: iconStart,
          iconStars: iconStars5,
          description:
            "I particularly enjoy the challenges available on the website. Nothing like a bit of fun competition to motivate me to act.",
          location: "Nairobi, Kenya",
          iconEnd: iconEnd,
        },
        {
          photo: photoTom,
          person: "Maren",
          iconStart: iconStart,
          iconStars: iconStars5,
          description:
            "Being busy at all times, I love the integration with Strava, which helps me log my activities in Quambio automatically.",
          location: "Zurich, Switzerland",
          iconEnd: iconEnd,
        },
        {
          photo: photoTom,
          person: "Christine",
          iconStart: iconStart,
          iconStars: iconStars5,
          description:
            "We have used Quambio to run challenges for our organisation and love the human experience we draw out of it.",
          location: "Neuchâtel, Switzerland",
          iconEnd: iconEnd,
        },
      ],
    };
  },
  methods: {
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({
        top: 0,
        left: -this.$refs.scrollContainer.clientWidth / 2,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({
        top: 0,
        left: this.$refs.scrollContainer.clientWidth / 2,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  width: 150%;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }

  .card-wrapper {
    width: 300%;
  }

  .custom-hidden {
    display: none;
  }
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  h1 {
    font-size: 1.5rem;
    /* Réduit la taille du titre principal */
  }

  p {
    font-size: 0.875rem;
    /* Réduit la taille du texte des paragraphes */
  }

  .card-wrapper {
    width: 300%;
  }

  .custom-hidden {
    display: none;
  }
}

.left-gradient-button {
  background: linear-gradient(
    145deg,
    /* Angle du dégradé */ #168d9a 10%,
    /* Couleur en haut à gauche */ #350b3e 80%,
    /* Couleur au milieu */ #1a1a1a 100% /* Couleur en bas à droite */
  );
}

.right-gradient-button {
  background: linear-gradient(
    -145deg,
    /* Angle du dégradé */ #168d9a 10%,
    /* Couleur en haut à gauche */ #350b3e 80%,
    /* Couleur au milieu */ #1a1a1a 100% /* Couleur en bas à droite */
  );
}
</style>
