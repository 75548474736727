<template>
  <div id="join"
    class="min-h-screen w-full bg-joinwithus-background bg-cover bg-center flex items-center justify-center">
    <div class="w-full lg:w-4/5 xl:w-95p flex flex-col lg:flex-row items-center justify-between text-left gap-6">
      <!-- Première section avec le titre, le paragraphe et le bouton -->
      <section class="section-content w-full lg:w-1/2 pt-6 pb-6">
        <div class="bg-light rounded-2xl pt-6 pb-6 pl-24 pr-24 custom-shadow custom-border custom-blur">
          <img :src="logoSrc" alt="Logo Quambio" class="mx-auto" />
          <h2 class="text-2xl font-sans mb-6 text-secondary text-center">
            {{
              currentForm === "signup"
                ? "Create your account"
                : "Sign in to your account"
            }}
          </h2>
          <div class="flex">
            <CustomButton :customClass="currentForm === 'signup'
              ? 'bg-accent text-primary mb-4 rounded-tl-bl'
              : 'bg-dark text-secondary mb-4 rounded-tl-bl'
              " paddingX="px-12" @click="setCurrentForm('signup')" :fullWidth="true">
              Sign up
            </CustomButton>
            <CustomButton :customClass="currentForm === 'login'
              ? 'bg-accent text-primary mb-4 rounded-tr-br'
              : 'bg-dark text-secondary mb-4 rounded-tr-br'
              " paddingX="px-12" @click="setCurrentForm('login')" :fullWidth="true">
              Sign in
            </CustomButton>
          </div>
          <div v-if="currentForm === 'signup'">
            <SignupForm @signup="signup" :errorMessage="signupErrorMessage" :isLoading="isLoading" />
          </div>
          <div v-else-if="currentForm === 'login'">
            <LoginForm @login="login" :errorMessage="loginErrorMessage" :isLoading="isLoading" />
          </div>
        </div>
      </section>

      <!-- Deuxième section avec l'image -->
      <section class="section-image w-full lg:w-1/2 p-6 flex items-center justify-center">
        <img :src="backgroundConnection" alt="Conexion" class="max-h-4/5 object-contain" />
      </section>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/elements/CustomButton.vue";
import logo from "@/assets/images/Logo-Quambio.svg";
import backgroundConnection from "@/assets/images/Background-conexion.svg";
import SignupForm from "@/components/SignupForm.vue";
import LoginForm from "@/components/LoginForm.vue";
import axios from "axios";

export default {
  name: "CreateYourAccount",
  components: {
    CustomButton,
    SignupForm,
    LoginForm,
  },
  data() {
    return {
      logoSrc: logo,
      backgroundConnection,
      currentForm: "signup",
      signupErrorMessage: {
        local: {},
        api: {}
      },
      loginErrorMessage: {
        local: {},
        api: {}
      },
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    setCurrentForm(form) {
      this.currentForm = form;
    },
    async signup(userDetails) {
      this.signupErrorMessage.local = {};
      this.signupErrorMessage.api = {};
      if (userDetails.email === "" || userDetails.name === "") {
        // Local check for password mismatch before emitting an event
        this.signupErrorMessage.local.message = ["validation.empty"];
        return;
      }
      if (
        userDetails.password !== userDetails.password_confirmation ||
        userDetails.password === ""
      ) {
        this.signupErrorMessage.local.password = ["validation.confirmed"];
        return;
      }
      if (userDetails.acceptTerms !== true) {
        this.signupErrorMessage.local.acceptTerms = ["validation.acceptTerms"]
        return;
      }

      try {
        this.isLoading = true;
        const response = await axios.post("/api/signup", userDetails);
        if (response.status === 200) {
          this.signupErrorMessage = {};
          console.log("Signup successful", response.data);
          window.location.href = "/app";
        } else {
          this.signupErrorMessage.api.message = response.data.message;
        }
      } catch (error) {
        if (error.response.data.errors) {
          this.signupErrorMessage.api = error.response.data.errors;
          console.error("Signup error:", error.response.data.errors);
        } else {
          this.signupErrorMessage.local.message = ['validation.errorSignup']
          console.error("Unexpected error happened");
        }
      } finally {
        this.isLoading = false;
      }
    },
    async login(credentials) {
      this.loginErrorMessage.local = {};
      this.loginErrorMessage.api = {};
      if (credentials.email === "" || credentials.password === "") {
        // Local check for password mismatch before emitting an event
        this.loginErrorMessage.local.message = ["validation.empty"];
        return;
      }
      try {
        this.isLoading = true;
        const response = await axios.post("/api/login", credentials);
        if (response.status === 200) {
          console.log("Login successful", response.data);
          window.location.href = "/app";
        } else {
          console.error("Login failed", response.data.message);
          this.loginErrorMessage.api.message = [response.data.message];
        }
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.loginErrorMessage.api = error.response.data.errors;
        } else {
          this.loginErrorMessage.local.message = ["validation.errorLogin"];
        }
        console.error("Login error:", error.response ? error.response.data.errors : error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-checkbox {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked {
  background-color: #00ffcc;
}

.custom-checkbox:checked:before {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #181818;
  font-size: 12px;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }

  .section-content {
    margin: 2rem 0 0 0;
  }

  .section-image {
    margin: 0 0 2rem 0;
  }

  .pl-24 {
    padding-left: 3rem;
  }

  .pr-24 {
    padding-right: 3rem;
  }
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  h2 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.875rem;
  }

  label {
    font-size: 0.875rem;
  }

  .p-4 {
    padding: 0;
  }

  .pl-24 {
    padding-left: 0;
  }

  .pr-24 {
    padding-right: 0;
  }

  /* Inverser l'ordre des sections pour les écrans mobiles */
  .section-content {
    order: 2;
  }

  .section-image {
    order: 1;
  }

  .custom-border {
    border: none !important;
  }

  .custom-blur {
    background: none !important;
    backdrop-filter: none !important;
  }

  .custom-shadow {
    box-shadow: none !important;
  }
}

.custom-hr {
  border: none;
  border-top: 0.5px solid #bcbcbc;
  width: 100%;
}

.custom-border {
  border: 0.5px solid #f3f7f9;
}

.custom-blur {
  background: linear-gradient(45deg,
      rgba(49, 9, 57, 0.24) 24%,
      rgba(2, 43, 57, 0.24) 50%,
      rgba(25, 82, 112, 0.24) 93%);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1;
}

.custom-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rounded-tr-br {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.rounded-tl-bl {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.btn-special-config {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0.5px solid #dddde0;
}

.leading-normal {
  line-height: 2;
}
</style>
