<template>
  <div>
    <NavigationMenu />
    <JoinWithUs />
    <LearnAboutUs />
    <CreateYourAccount />
    <WhatPeopleSay />
    <MediaAndPartners />
    <Footer />
  </div>
</template>

<script>
import NavigationMenu from "@/components/NavigationMenu.vue";
import JoinWithUs from "@/components/JoinWithUs.vue";
import LearnAboutUs from "@/components/LearnAboutUs.vue";
import CreateYourAccount from "@/components/CreateYourAccount.vue";
import WhatPeopleSay from "@/components/WhatPeopleSay.vue";
import MediaAndPartners from "@/components/MediaAndPartners.vue";
import Footer from "@/components/FooterZone.vue";

export default {
  name: "HomePage",
  components: {
    NavigationMenu,
    JoinWithUs,
    LearnAboutUs,
    CreateYourAccount,
    WhatPeopleSay,
    MediaAndPartners,
    Footer,
  },
};
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
console.log(timezone);
document.cookie = `tz=${timezone}`;
</script>

<style scoped>
/* Styles globaux pour la page principale */
</style>
