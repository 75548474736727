<template>
  <div>
    <form @submit.prevent="handleLogin">
      <FormTextZone id="email" v-model:value="email" label="Your Email *" placeholder="Email address" iconType="mail" />
      <div v-if="props.errorMessage.local.email" class="error-message">
        {{ $t(`error.${props.errorMessage.local.email[0]}`, { attribute: 'email' }) }}
      </div>
      <div v-if="props.errorMessage.api.email" class="error-message">
        {{ props.errorMessage.api.email[0] }}
      </div>
      <FormTextZone id="password" v-model:value="password" label="Password *" placeholder="Password" iconType="lock"
        :isPassword="true" />
      <div v-if="props.errorMessage.local.password" class="error-message">
        {{ $t(`error.${props.errorMessage.local.password[0]}`, { attribute: 'password' }) }}
      </div>
      <div v-if="props.errorMessage.api.password" class="error-message">
        {{ props.errorMessage.api.password[0] }}
      </div>
      <div v-if="props.errorMessage.local.message || props.errorMessage.api.message" class="error-message">
        {{ $te(`error.${props.errorMessage.local.message[0]}`) ? $t(`error.${props.errorMessage.local.message[0]}`) :
          props.errorMessage.api.message[0] }}
      </div>
      <CustomButton :disabled="props.isLoading" customClass="bg-accent text-primary my-6" paddingX="px-12"
        :fullWidth="true" :showArrow="true">
        Sign in
      </CustomButton>
    </form>
  </div>
</template>


<script setup>
import { ref, defineProps } from "vue";
import CustomButton from "@/components/elements/CustomButton.vue";
import FormTextZone from "@/components/elements/FormTextZone.vue";

// Définition des props émis, en utilisant defineEmits pour spécifier les événements que ce composant peut émettre.
const emit = defineEmits(["login"]);

// Définition des réactifs pour les champs de formulaire.
const email = ref("");
const password = ref("");
const props = defineProps({
  errorMessage: Object,
  isLoading: Boolean,
});

// Fonction pour gérer la soumission du formulaire de connexion.
function handleLogin() {
  if (props.isLoading) return

  // Émettre les données au composant parent quand le formulaire est soumis.
  emit("login", { email: email.value, password: password.value });
}
</script>

<style scoped>
.error-message {
  color: rgb(255 129 129);
  margin-top: 5px;
}
</style>
