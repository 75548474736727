import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  }
  // Ajoute d'autres routes ici si nécessaire
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router