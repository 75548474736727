<template>
    <div
        class="flex flex-col items-center justify-center w-full min-h-screen bg-center bg-cover bg-joinwithus-background"
    >
        <div
            class="flex flex-col items-center justify-between w-full mx-auto text-left container-principal lg:w-4/5 xl:w-95p lg:flex-row"
        >
            <!-- Première section avec le titre, le paragraphe et le bouton -->
            <section class="w-full lg:w-1/2">
                <div class="mb-10-percent">
                    <h1 class="mb-4 font-sans text-4xl text-secondary">Welcome to Quambio</h1>
                    <p class="mb-4 font-sans text-lg text-secondary">
                        We are a community of like-minded people, who care about the environment and
                        who understand that our individual combined impact matters, whatever its
                        size.<br /><br />
                        Join us to learn more about the climate, to share with others and to
                        participate in mobilitiy challenges supported by great organisations and
                        clubs.<br /><br />
                        We look forward to seeing you on our platform!
                    </p>
                    <CustomButton
                        customClass="bg-accent text-primary mb-4 hover:text-black w-44"
                        paddingX="px-12"
                        href="#join"
                    >
                        Join Us
                    </CustomButton>
                </div>
                <div class="flex items-center">
                    <img :src="networkPeople" alt="Network people" class="w-40 mr-4 users" />
                    <p class="font-sans text-lg text-secondary">50K+ People Join Our Teams</p>
                </div>
            </section>

            <!-- Deuxième section avec l'image "Network" -->
            <section class="flex items-center justify-center w-full p-4 lg:w-1/2">
                <img :src="network" alt="Network" class="object-contain w-full max-h-4/5" />
            </section>
        </div>
    </div>
</template>

<script>
import CustomButton from "@/components/elements/CustomButton.vue";
import networkPeople from "@/assets/images/NetworkPeople.webp";
import network from "@/assets/images/Network.webp";

export default {
    name: "JoinWithUs",
    components: {
        CustomButton,
    },
    methods: {
        handleClick(event) {
            console.log("Button clicked!", event);
        },
    },
    computed: {
        isActive() {
            return (page) => {
                return this.$route.path.includes(page) ? "active" : "";
            };
        },
    },
    data() {
        return {
            networkPeople,
            network,
        };
    },
};
</script>

<style scoped>
a:hover {
    color: #00ffcc;
}

a {
    position: relative;
}

.marker {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: transparent;
    /* Par défaut, invisible */
}

.marker.active {
    background-color: #00ffcc;
}

.container-principal {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.custom-button-login {
    background-image: linear-gradient(to bottom right, #05485c, #045f6b);
    color: #ffffff;
    padding: 0.2rem 0.5rem;
}

.mb-10-percent {
    margin-bottom: 10%;
}

@media (min-width: 600px) and (max-width: 1024px) {
    .xl\:w-95p {
        width: 95%;
    }

    .container-principal {
        padding-top: 7rem;
    }

    .mb-10-percent {
        margin-bottom: 2rem;
    }
}

@media (max-width: 599px) {
    .xl\:w-95p {
        width: 90%;
    }

    .container-principal {
        padding-top: 7rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.875rem;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .users {
        max-width: 30%;
    }
}
</style>
