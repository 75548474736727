<template>
  <a v-if="href" :href="href" :class="buttonClasses">
    <slot></slot>
  </a>
  <button v-else :class="buttonClasses" @click="handleClick">
    <span v-if="iconSrc && iconPosition === 'left'" class="icon-container">
      <img :src="iconSrc" alt="Icon" />
    </span>
    <span class="button-text">
      <slot></slot>
    </span>
    <span v-if="iconSrc && iconPosition === 'right'" class="icon-container">
      <img :src="iconSrc" alt="Icon" />
    </span>
    <span v-if="showArrow" class="icon-container">
      <img :src="arrowSrc" alt="Arrow Icon" />
    </span>
  </button>
</template>

<script>
import arrow from "@/assets/images/icons/arrow-dark.svg";

export default {
  name: "CustomButton",
  props: {
    href: String,
    fullWidth: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    paddingX: {
      type: String,
      default: "px-4",
    },
    iconSrc: {
      type: String,
      default: "",
    },
    iconPosition: {
      type: String,
      default: "right",
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      return [
        "py-2 rounded flex items-center justify-center",
        this.paddingX,
        this.fullWidth ? "w-full" : "",
        this.customClass,
        this.disabled ? "opacity-50 cursor-not-allowed" : "",
      ].join(" ");
    },
    arrowSrc() {
      return arrow;
    },
    isDisabled() {
      return this.disabled;
    },
  },
  methods: {
    handleClick() {
      //this.$emit("click", event);
    },
  },
};
</script>

<style scoped>
a:hover {
  color: black !important;
}
.button-text {
  display: inline-flex;
  align-items: center;
}

.icon-container {
  margin: 0 0.5rem;
  display: inline-flex;
  align-items: center;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 599px) {
  span {
    font-size: 0.875rem;
  }
}
</style>
