<template>
  <div class="relative">
    <div
      class="absolute inset-0 transform bg-custom-gradient p-1 rounded-2xl custom-shadow z-10 ml-4 mr-4 transition-all hover:rotate-[-5deg]"
    ></div>
    <div
      class="relative bg-custom-gradient p-0.5 rounded-2xl custom-shadow z-20 ml-4 mr-4 pointer-events-none"
    >
      <div class="p-6 bg-white rounded-xl">
        <div
          class="flex flex-row items-center justify-center gap-4 bg-white rounded-2xl content-card"
        >
          <!-- <img :src="photo" alt="photo" class="w-1/3 h-auto" /> -->
          <img src="@/assets/images/Bars.svg" alt="bars" class="bars" />
          <div class="flex flex-col items-start justify-center w-full text-left">
            <img :src="iconStart" alt="iconStart" class="w-6 h-6 mb-2" />
            <p class="text-gray-600 text-s">{{ description }}</p>
            <!-- Ligne horizontale grise -->
            <hr class="w-full mt-6 mb-6 custom-hr" />
            <div class="flex flex-col w-full">
              <div class="flex justify-end mt-2">
                <img :src="iconStars" alt="iconStars" class="w-24 h-6" />
              </div>
              <div class="flex flex-col items-start">
                <h3 class="mb-2 font-semibold text-md">{{ person }}</h3>
                <p class="text-xs text-gray-600">{{ location }}</p>
              </div>
              <div class="flex justify-end mt-2">
                <img :src="iconEnd" alt="iconEnd" class="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomReviewCard",
  props: {
    photo: {
      type: String,
      required: true,
    },
    person: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    iconStart: {
      type: String,
      required: true,
    },
    iconEnd: {
      type: String,
      required: true,
    },
    iconStars: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.custom-hr {
  border: none;
  border-top: 0.5px solid #bcbcbc;
  width: 100%;
}

@media (min-width: 600px) and (max-width: 1024px) {
  h3 {
    font-size: 1rem;
    /* Réduit la taille du titre principal */
  }

  p {
    font-size: 0.875rem;
    /* Réduit la taille du texte des paragraphes */
  }

  .bars {
    display: none;
  }
}

@media (max-width: 599px) {
  h3 {
    font-size: 1rem;
    /* Réduit la taille du titre principal */
  }

  p {
    font-size: 0.875rem;
    /* Réduit la taille du texte des paragraphes */
  }

  .bars {
    display: none;
  }

  .mt-6 {
    margin-top: 1rem;
  }

  .mb-6 {
    margin-bottom: 0rem;
  }

  .content-card {
    flex-direction: column;
  }
}
</style>
