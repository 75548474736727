<template>
  <div class="relative h-full">
    <div
      class="absolute inset-0 transform hover:rotate-[-5deg] bg-custom-gradient p-1 rounded-2xl custom-shadow z-10 transition-all h-full"
      :class="link ? 'cursor-pointer' : ''"
      @click="openExternalLink(link)"
    ></div>
    <div
      class="relative bg-custom-gradient p-0.5 rounded-2xl custom-shadow z-20 pointer-events-none h-full"
      @click="console.log('lol')"
    >
      <div class="relative p-6 bg-white rounded-xl h-full">
        <div class="flex flex-col items-center justify-center bg-white rounded-2xl">
          <div class="p-4 mb-4 rounded-full bg-gradient-to-br from-green-400 to-blue-500">
            <img :src="icon" alt="icon" class="w-12 h-12" />
          </div>
          <h3 class="mb-2 text-lg font-semibold text-center">{{ title }}</h3>
          <p class="mb-2 text-center text-gray-600 text-s">{{ description }}</p>
          <a v-if="link" :href="link" target="_blank" class="text-lg font-semibold text-center">
            {{ linkText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCard",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    linkText: {
      type: String,
      required: false,
    },
  },
  methods: {
    openExternalLink(url) {
      url ? window.open(url, "_blank") : {};
    },
  },
};
</script>

<style scoped>

.custom-link {
  color: inherit;
  /* Utilise la couleur de texte par défaut */
  text-decoration: none;
  /* Supprime la décoration par défaut des liens */
  text-decoration: underline;
}

.custom-link:hover {
  /* Ajoute une décoration au survol */
  color: #239bde;
}

@media (max-width: 599px) {
  h3 {
    font-size: 1rem;
    /* Réduit la taille du titre principal */
  }

  p {
    font-size: 0.875rem;
    /* Réduit la taille du texte des paragraphes */
  }

  a {
    font-size: 0.875rem;
    /* Réduit la taille du texte des paragraphes */
  }
}
</style>
