import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import './assets/styles/tailwind.css'
import { createI18n } from 'vue-i18n'

import en from './i18n/en.json'

const i18n = createI18n({
  locale: 'en', // Définir la langue par défaut
  fallbackLocale: 'en', // Langue de repli si la langue actuelle n'est pas disponible
  messages: {
    en,
  },
})

createApp(App)
  .use(router)
  .use(PrimeVue, { unstyled: true })
  .use(i18n)
  .mount('#app')