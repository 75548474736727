<template>
  <div class="flex flex-col w-full mx-auto lg:w-4/5 xl:w-95p" id="news">
    <div class="flex flex-col items-center justify-center w-full mx-auto mb-12 xl:w-95p">
      <!-- Première partie : Titre et texte alignés verticalement -->
      <h1 class="mb-4 text-4xl text-primary">{{ title }}</h1>
      <p class="text-lg text-center text-dark">{{ text }}</p>
    </div>
  </div>
  <div class="flex flex-col w-full mx-auto mt-12 mb-24 lg:w-4/5 xl:w-95p">
    <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4 md:max-h-[42rem] lg:max-h-[24rem]">
      <CustomCard v-for="(card, index) in cards" :key="index" :icon="card.icon" :link="card.link"
        :linkText="card.title" />
    </div>
  </div>
</template>

<script>
import CustomCard from "./elements/CustomCard.vue";
import linkedin from "@/assets/images/icons/linkedin.svg";
import instagram from "@/assets/images/icons/instagram.svg";
import video from "@/assets/images/icons/video.svg";
import forum from "@/assets/images/icons/forum.svg";

export default {
  name: "MediaAndPartners",
  props: {
    title: {
      type: String,
      default: "Media And Partners",
    },
    text: {
      type: String,
      default: "Learn more about via our social platforms and media appearances.",
    },
  },
  components: {
    CustomCard,
  },
  data() {
    return {
      cards: [
        {
          icon: linkedin,
          link: "https://www.linkedin.com/company/quambio/?originalSubdomain=ch",
          title: "Quambio on LinkedIn",
        },
        {
          icon: instagram,
          link: "https://www.instagram.com/quambio/",
          title: "Quambio on Instagram",
        },
        {
          icon: video,
          link: "https://youtu.be/2NTG5tKahy4?si=lKLdJeIUZK7Zaa8d",
          title: "Le Temps / Eureka video coverage (In French)",
        },
        {
          icon: forum,
          link: "https://www.rts.ch/play/tv/forum/video/forum-des-idees-une-application-qui-recompense-la-mobilite-douce?urn=urn:rts:video:12652807",
          title: "Our founder live on RTS Forum",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  .grid {
    grid-template-columns: repeat(1, 1fr);
    /* Une carte par ligne pour petits écrans */
  }

  h1 {
    font-size: 1.5rem;
    /* Réduit la taille du titre principal */
  }

  p {
    font-size: 0.875rem;
    /* Réduit la taille du texte des paragraphes */
  }

  .mb-12 {
    margin-bottom: 0.5rem;
  }

  .mt-12 {
    margin-bottom: 0;
  }

  .grid:last-child {
    margin-bottom: 4rem;
  }
}
</style>
