<template>
  <div class="w-full lg:w-4/5 xl:w-95p mx-auto flex flex-col" id="about">
    <div
      class="flex flex-col items-center justify-center w-full mb-12 mt-12 xl:w-95p mx-auto"
    >
      <!-- Première partie : Titre et texte alignés verticalement -->
      <h1 class="text-4xl mb-4 text-primary">{{ title }}</h1>
      <p class="text-lg text-center text-dark">{{ text }}</p>
    </div>

    <!-- Deuxième partie : Deux div alignées horizontalement -->
    <div class="flex justify-center flex-col-mobile gap-6">
      <div class="w-1/2 bg-secondary p-12 rounded-2xl custom-shadow text-dark">
        <!-- Contenu de la première div -->
        <h1 class="text-2xl mb-4 text-primary text-left">
          {{ subtitle }}
        </h1>
        <p v-html="textcard" class="text-lg text-left text-light"></p>
      </div>
      <div
        class="w-1/2 bg-cycling-image bg-cover bg-center rounded-2xl custom-shadow min-h-48 sm:max-h-60"
      ></div>
    </div>
  </div>
  <div class="w-full lg:w-4/5 xl:w-95p mx-auto flex flex-col mb-24 mt-12">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 md:max-h-[42rem] lg:max-h-[24rem]">
      <CustomCard
        v-for="(card, index) in cards"
        :key="index"
        :icon="card.icon"
        :title="card.title"
        :description="card.description"
      />
    </div>
  </div>
</template>

<script>
import CustomCard from "./elements/CustomCard.vue";
import community from "@/assets/images/icons/community.svg";
import settings from "@/assets/images/icons/settings.svg";
import bike from "@/assets/images/icons/bike.svg";
import trophy from "@/assets/images/icons/trophy.svg";

export default {
  name: "LearnAboutUs",
  props: {
    title: {
      type: String,
      default: "Learn About Us",
    },
    text: {
      type: String,
      default:
        "We are a Swiss environmental company founded in 2019, working towards emission reductions, for the benefit of everyone.",
    },
    subtitle: {
      type: String,
      default: "Our Story",
    },
    textcard: {
      type: String,
      default:
        "We were born out of a simple belief… that people can make a difference in our collective fight against climate change.<br><br>That simple belief led us to building tech to track mobility emissions to help people understand that their choices matter. Our platform offers engaging solutions to help enact emissions reduction, including mobility challenges.<br><br>No action is too small, so join us and don’t hesitate to have fun along the way!",
    },
  },
  components: {
    CustomCard,
  },
  data() {
    return {
      cards: [
        {
          icon: community,
          title: "Join our community",
          description:
            "Create a profile to join our community, track your actions and network with like-minded people.",
        },
        {
          icon: settings,
          title: "Log activities",
          description:
            "Download our App, integrate with solutions such as Strava, or log your trips manually to track your impact.",
        },
        {
          icon: bike,
          title: "All modes welcomed",
          description:
            "Whether you ride a bike, walk, use public transport or even car-sharing, we welcome you in our platform!",
        },
        {
          icon: trophy,
          title: "Our challenges",
          description:
            "And for those who are competitive, join our challenges to earn cool prizes and badges ",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-cycling-image {
  background-image: url("@/assets/images/about-us-people.webp");
  background-size: cover;
  background-position: center;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }

  .flex-col-mobile {
    flex-direction: column;
    align-items: center;
    margin: 0 0.5rem;
  }

  .flex-col-mobile > div {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    margin-bottom: 1rem;
  }

  .flex-col-mobile > div:last-child {
    margin-bottom: 0;
  }

  .min-h-48 {
    min-height: 42rem;
  }

  .bg-cycling-image {
   height: 20rem;
   min-height: 20rem;
}
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  .grid-cols-1 {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.875rem;
  }

  .min-h-48 {
    min-height: 14rem;
    /* 48px rem => 12rem (192px) */
  }

  .p-12 {
    padding: 1.5rem;
  }

  .flex-col-mobile {
    flex-direction: column;
    align-items: center;
    margin: 0 0.5rem;
  }

  .flex-col-mobile > div {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    margin-bottom: 1rem;
  }

  .flex-col-mobile > div:last-child {
    margin-bottom: 0;
  }
}
</style>
