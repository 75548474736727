<template>
  <div class="relative flex flex-col">
    <label :for="id" class="mb-1 mt-6 text-white">
      <span v-html="formattedLabel"></span>
    </label>
    <div class="relative">
      <input
        :id="id"
        :type="inputType"
        :placeholder="placeholder"
        class="w-full px-4 py-2 pl-10 border rounded-md input text-secondary"
        :value="value"
        @input="emit('update:value', $event.target.value)"
        v-bind="$attrs"
      />
      <div
        v-if="iconSrc"
        class="absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <img :src="iconSrc" class="w-5 h-5" />
      </div>
      <div
        v-if="isPassword"
        @click="togglePasswordVisibility"
        class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
      >
        <img :src="eyeIconSrc" class="w-5 h-5" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import eyeOpenIcon from "@/assets/images/icons/visibility-off.svg";
import eyeClosedIcon from "@/assets/images/icons/visibility.svg";
import mailIcon from "@/assets/images/icons/mail.svg";
import lockIcon from "@/assets/images/icons/lock.svg";
import personIcon from "@/assets/images/icons/person.svg";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  iconType: {
    type: String,
    default: "",
  },
  isPassword: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    default: "",
  },
  helperText: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:value"]);

const showPassword = ref(false);

const inputType = computed(() => {
  return props.isPassword && !showPassword.value ? "password" : "text";
});

const eyeIconSrc = computed(() => {
  return showPassword.value ? eyeOpenIcon : eyeClosedIcon;
});

const iconSrc = computed(() => {
  switch (props.iconType) {
    case "mail":
      return mailIcon;
    case "lock":
      return lockIcon;
    case "person":
      return personIcon;
    default:
      return "";
  }
});

const formattedLabel = computed(() => {
  return props.label.replace("*", '<span style="color: #FF0202">*</span>');
});

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}
</script>

<style scoped>
.input {
  background: rgba(255, 255, 255, 0.1);
  border-color: #f3f7f9;
  border-width: 0.5px;
}

@media (max-width: 599px) {
  span {
    font-size: 0.875rem; /* Réduit la taille du texte du bouton */
  }

  ::placeholder {
    font-size: 0.875rem;
  }
}
</style>
