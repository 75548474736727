<template>
  <div>
    <form @submit.prevent="handleSignup">

      <FormTextZone id="name" v-model:value="name" label="Your Name *" placeholder="Name" iconType="person" />
      <div v-if="props.errorMessage && props.errorMessage.local && props.errorMessage.local.name" class="error-message">
        {{ $t(`error.${props.errorMessage.local.name[0]}`, { attribute: 'name' }) }}
      </div>
      <div v-if="props.errorMessage && props.errorMessage.api && props.errorMessage.api.name" class="error-message">
        {{ props.errorMessage.name[0] }}
      </div>
      
      <FormTextZone id="email" v-model:value="email" label="Your Email *" placeholder="Email address" iconType="mail" />
      <div v-if="props.errorMessage && props.errorMessage.local && props.errorMessage.local.email" class="error-message">
        {{ $t(`error.${props.errorMessage.local.email[0]}`, { attribute: 'email' }) }}
      </div>
      <div v-if="props.errorMessage && props.errorMessage.api && props.errorMessage.api.email" class="error-message">
        {{ props.errorMessage.api.email[0] }}
      </div>
      
      <FormTextZone id="password" v-model:value="password" label="Password *" placeholder="Password" iconType="lock"
      :isPassword="true" />
      
      <FormTextZone id="password_confirmation" v-model:value="password_confirmation" label="Confirm Password *"
      placeholder="Confirm Password" iconType="lock" :isPassword="true" />
      <div v-if="props.errorMessage && props.errorMessage.local && props.errorMessage.local.password" class="error-message">
        {{ $t(`error.${props.errorMessage.local.password[0]}`, { attribute: 'password' }) }}
      </div>
      <div v-if="props.errorMessage && props.errorMessage.api && props.errorMessage.api.password" class="error-message">
        {{ props.errorMessage.api.password[0] }}
      </div>
      
      <div class="terms-container my-4 flex flex-row items-center">
        <input type="checkbox" id="acceptTerms" v-model="acceptTerms"
        class="custom-checkbox" />
        <label for="acceptTerms" class="text-sm ml-2 text-secondary hover:cursor-pointer">
          Accept <a href="https://www.quambio.ch/privacy-policy/" target="_blank"
          class="text-accent underline text-secondary">terms and conditions</a>
        </label>
      </div>
      <div v-if="props.errorMessage && props.errorMessage.local && props.errorMessage.local.acceptTerms" class="error-message">
        {{ $t(`error.${props.errorMessage.local.acceptTerms[0]}`) }}
      </div>
      <div v-if="props.errorMessage && props.errorMessage.api && props.errorMessage.api.acceptTerms" class="error-message">
        {{ props.errorMessage.api.acceptTerms[0] }}
      </div>
      <div v-if="props.errorMessage && props.errorMessage.local && props.errorMessage.local.message" class="error-message">
        {{ $t(`error.${props.errorMessage.local.message[0]}`) }}
      </div>
      
      <CustomButton :disabled="props.isLoading" customClass="bg-accent text-primary my-6" paddingX="px-12"
        :fullWidth="true" :showArrow="true">
        Sign up
      </CustomButton>
    </form>
  </div>
</template>

<script setup>
import CustomButton from "@/components/elements/CustomButton.vue";
import FormTextZone from "@/components/elements/FormTextZone.vue";
import { ref, defineProps } from "vue";

const emit = defineEmits(["signup"]);

const props = defineProps({
  errorMessage: Object,
  isLoading: Boolean
});

// const formError = ref("");
const name = ref("");
const email = ref("");
const password = ref("");
const password_confirmation = ref("");
const acceptTerms = ref(false);

function handleSignup() {
  // formError.value = "";
  // if (email.value === "" || name.value === "") {
  //   // Local check for password mismatch before emitting an event
  //   formError.value = "Please fill the form";
  //   return;
  // }
  // if (password.value !== password_confirmation.value || password.value === "") {
  //   formError.value = "Passwords do not match or are empty"
  //   return;
  // }
  if (props.isLoading) return
  emit("signup", {
    name: name.value,
    email: email.value,
    password: password.value,
    password_confirmation: password_confirmation.value,
    acceptTerms: acceptTerms.value
  });
}
</script>

<style scoped>
.error-message {
  color: rgb(255, 129, 129);
  margin-top: 5px;
  font-size: 0.875em;
}

.custom-checkbox {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: relative;
  border: 2px solid #f3f7f9;
  border-radius: 0.25rem;
  appearance: none;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-checkbox:checked {
  background-color: var(--color-accent);
}

.custom-checkbox:checked::after {
  content: '';
  width: 8px;
  height: 8px;
  background-color: white;
  position: absolute;
  border-radius: 2px;
}
</style>
