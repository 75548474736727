<template>
  <div
    class="flex flex-col items-center justify-center w-full bg-center bg-cover bg-joinwithus-background"
  >
    <div
      class="flex flex-col items-center justify-center w-full pt-12 pb-12 bg-center bg-cover bg-joinwithus-background"
    >
      <div
        class="flex flex-col items-center justify-between w-full text-left lg:w-4/5 xl:w-95p lg:flex-row"
      >
        <!-- Première section avec le titre, le paragraphe et le bouton -->
        <section class="w-full pb-6 lg:w-1/2" id="contact-us">
          <div
            class="pt-6 pb-6 pl-24 pr-24 bg-light rounded-2xl custom-shadow custom-border custom-blur"
          >
            <div class="rounded-2xl custom-green">
              <h2 class="pt-6 mb-2 text-2xl text-center text-secondary">Contact Us</h2>
              <p class="mb-6 font-sans text-center text-md text-secondary">
                Any question or remarks? Let us know!
              </p>
            </div>

            <FormTextZone
              id="contact-form-name"
              label="Full name *"
              placeholder="Full name"
              iconType="person"
              v-model:value="name"
            />
            <div v-if="nameError" class="error-message">
              {{ nameError[0] }}
            </div>
            <FormTextZone
              id="contact-form-email"
              label="Your Email *"
              placeholder="Email address"
              iconType="mail"
              v-model:value="email"
            />
            <div v-if="emailError" class="error-message">
              {{ emailError[0] }}
            </div>
            <FormTextArea
              id="contact-form-message"
              label="Message *"
              placeholder="Type your message here"
              v-model:value="message"
            />
            <div v-if="messageError" class="error-message">
              {{ messageError[0] }}
            </div>

            <CustomButton
              customClass="bg-accent text-primary mb-4 mt-8"
              paddingX="px-12"
              @click="sendMessage"
              :fullWidth="true"
              :showArrow="true"
            >
              Send Message
            </CustomButton>
            <div class="text-green-500" v-if="messageSuccess">Message sent!</div>
            <div class="error-message" v-if="tooManyAttemptsError">
              {{ tooManyAttemptsError }}
            </div>
          </div>
        </section>

        <!-- Deuxième section avec l'image -->
        <section class="flex items-center justify-center w-full p-4 lg:w-1/2">
          <img :src="backgroundFooter" alt="Conexion" class="object-contain max-h-4/5" />
        </section>
        -
      </div>

      <!-- Séparation footer -->
      <hr class="w-full mb-12 custom-hr-green" />

      <!-- Menu zone -->
      <div class="flex flex-row items-start justify-between w-full gap-6 lg:w-4/5 xl:w-95p">
        <!-- Colonne 1 -->
        <div class="w-2/3 mr-6 text-left flex-2 text-secondary text-md">
          <img :src="logoSrc" alt="Logo Quambio" />
          <p class="w-2/3 mt-6 sm:w-full">
            Email us if you have any questions. You can also learn more about us at
            <a href="https://quambio.ch/" target="_blank" class="text-accent">quambio.ch</a>
          </p>
          <div class="flex items-center mt-6">
            <img :src="iconMail" alt="Icône mail" class="mr-2" />
            <p><a href="mailto:support@quambio.com" class="text-accent">support@quambio.com</a></p>
          </div>
          <div class="flex items-center mt-6">
            <img :src="iconPhone" alt="Icône téléphone" class="mr-2" />
            <p>+41 78 803 04 57</p>
          </div>
        </div>
        <!-- Colonne 4 -->
        <div class="w-1/3 text-left flex-2 text-secondary text-md">
          <h2 class="text-md text-secondary">Contact</h2>
          <p class="mt-6">Rue d'Evole 46<br />2000 Neuchâtel,<br />Switzerland</p>

          <div class="flex flex-wrap items-center gap-4 mt-6">
            <a
              href="https://play.google.com/store/apps/details?id=ch.quambio.co2&amp;pcampaignid=web_share"
            >
              <img
                src="@/assets/images/playstore.png"
                class="w-1/3 sm:w-full md:w-full"
                alt="Play store"
              />
            </a>
            <a href="https://apps.apple.com/ch/app/quambio/id1556212615?l=en">
              <img
                src="@/assets/images/appstore.png"
                class="w-1/3 sm:w-full md:w-full"
                alt="App store"
              />
            </a>
          </div>
        </div>
      </div>

      <!-- Séparation footer -->
      <hr class="w-full mt-12 mb-6 custom-hr-green" />
      <p class="pb-3 mt-6 text-center text-secondary text-md">© Quambio SA, Copyrights 2024</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import CustomButton from "@/components/elements/CustomButton.vue";
import FormTextZone from "@/components/elements/FormTextZone.vue";
import FormTextArea from "@/components/elements/FormTextArea.vue";
import logoSrc from "@/assets/images/Logo-Quambio.svg";
import iconPhone from "@/assets/images/icons/phone.svg";
import iconMail from "@/assets/images/icons/mail.svg";
import backgroundFooter from "@/assets/images/Background-footer.svg";

const name = ref("");
const email = ref("");
const message = ref("");

// Error states
const nameError = ref(null);
const emailError = ref(null);
const messageError = ref(null);
const tooManyAttemptsError = ref(null);

const messageSuccess = ref(false);

const sendMessage = async () => {
  // Reset errors
  nameError.value = null;
  emailError.value = null;
  messageError.value = null;
  tooManyAttemptsError.value = null;
  messageSuccess.value = false;

  const postData = {
    name: name.value,
    email: email.value,
    message: message.value,
  };

  try {
    // On désactive eslint car recaptacha est importé via CDN
    // eslint-disable-next-line no-undef
    const token = await grecaptcha.enterprise.execute("6LfY2kYqAAAAACIzK2dVridaM4_ifpnc-Ng5KUIw", {
      action: "submit",
    });
    const response = await axios.post("/api/validate-captcha", { token: token, action: "submit" });
    if (response.data.tokenProperties.valid === false) {
      tooManyAttemptsError.value = "Recaptcha error, try again later";
      return;
    }

    await axios.post("/api/mail", postData);
    messageSuccess.value = true;
    name.value = "";
    email.value = "";
    message.value = "";
  } catch (error) {
    if (error.response && error.response.data.errors) {
      const errors = error.response.data.errors;
      if (errors.name) {
        nameError.value = errors.name;
      }
      if (errors.email) {
        emailError.value = errors.email;
      }
      if (errors.message) {
        messageError.value = errors.message;
      }
      if (errors.tooManyAttempts) {
        tooManyAttemptsError.value = errors.tooManyAttempts;
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error Message:", error.message);
      tooManyAttemptsError.value = "Unable to send message, try again later";
    }
  }
};
</script>

<style scoped>
.error-message {
  color: rgb(255, 129, 129);
  margin-top: 5px;
  font-size: 0.875em;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .xl\:w-95p {
    width: 95%;
  }

  .pl-24 {
    padding-left: 3rem;
  }

  .pr-24 {
    padding-right: 3rem;
  }
}

@media (max-width: 599px) {
  .xl\:w-95p {
    width: 90%;
  }

  h2 {
    font-size: 1.2rem;
  }

  p,
  label {
    font-size: 0.875rem;
  }

  .pl-24 {
    padding-left: 0;
  }

  .pr-24 {
    padding-right: 0;
  }

  .p-4 {
    padding: 0;
    margin-bottom: 2rem;
  }

  .mr-6 {
    margin-right: 0;
  }

  /* Colonne 1 */
  .flex-1:nth-child(1) {
    order: 1;
    margin-right: 0;
    margin-bottom: 2rem;
    min-width: 100%;
  }

  /* Colonne 2 et 3 ensemble */
  .flex-1:nth-child(2),
  .flex-1:nth-child(3) {
    order: 2;
    flex-basis: 45%;
    max-width: 45%;
    margin-right: 0;
    justify-content: space-between;
  }

  /* Aligner colonne 2 et 3 sur la même ligne */
  .w-full.lg\:w-4\/5.xl\:w-95p.flex.flex-row.items-start.justify-center.gap-6 {
    flex-direction: row;
    flex-wrap: wrap;
  }

  /* Colonne 4 */
  .flex-1:nth-child(4) {
    order: 3;
    margin-right: 0;
    margin-top: 2rem;
    min-width: 100%;
  }

  .custom-shadow {
    box-shadow: none !important;
  }

  .custom-border {
    border: none !important;
  }

  .custom-blur {
    background: none !important;
  }

  .bg-light {
    background-color: transparent !important;
  }
}

.custom-hr-green {
  border: none;
  height: 1px;
  width: 90%;
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 0) 0%,
    rgba(0, 255, 204, 1) 5%,
    rgba(74, 255, 219, 1) 50%,
    rgba(0, 255, 204, 1) 95%,
    rgba(0, 255, 204, 0) 100%
  );
}

.custom-border {
  border: 0.5px solid #f3f7f9;
}

.custom-blur {
  background: linear-gradient(
    45deg,
    rgba(49, 9, 57, 0.24) 24%,
    rgba(2, 43, 57, 0.24) 50%,
    rgba(25, 82, 112, 0.24) 93%
  );
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1;
}

.custom-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-green {
  border: 0.5px solid #00ffcc;
  background-color: rgba(0, 255, 204, 0.3);
}

.icon-container {
  background-color: rgba(188, 188, 188, 0.2);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: block;
}
</style>
