<template>
    <div class="relative flex flex-col">
        <label :for="id" class="mb-1 mt-6 text-white">
            <span v-html="formattedLabel"></span>
        </label>
        <div class="relative">
            <textarea :id="id" :placeholder="placeholder"
                class="w-full px-4 py-2 border rounded-md textarea text-secondary" v-bind="$attrs" @input="$emit('update:value', $event.target.value)"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormTextArea",
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        helperText: {
            type: String,
            default: "",
        },
    },
    computed: {
        formattedLabel() {
            return this.label.replace("*", '<span style="color: #FF0202">*</span>');
        },
    },
};
</script>

<style scoped>
.textarea {
    background: rgba(255, 255, 255, 0.1);
    border-color: #f3f7f9;
    border-width: 0.5px;
    min-height: 150px;
}
</style>